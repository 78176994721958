import axios from 'axios'

import useStore from '../store'

export const BASE_URL = process.env.REACT_APP_BASE_URL
const AUTH_TOKEN = process.env.REACT_APP_AUTH_TOKEN // Admin
// const AUTH_TOKEN = '7|5DAFwb1AIQvrJzVdCCG1vNjsqe1bzQYWqx0RMPjy' // Technical
export interface ResponseError {
  status: number
  message: string
  errors?: { [key: string]: string[] }
}

export interface ResponseMeta {
  current_page: number
  from: number
  last_page: number
  links: any[]
  path: string
  per_page: number
  to: number
  total: number
}

export type SortDirection = 'desc' | 'asc' | ''

export interface IFilter {
  sort?: string
  search?: string
  sortBy?: string
  sortDirection?: SortDirection
  pageSize?: number
  currentPage?: number
}
export const PAGE_SIZE = 20
export const PAGE_SIZE_RECENT = 10
export const PAGE_SIZE_SEARCH_COMPLETE = 10
export const PAGE_SIZE_DROPDOWN = 10

export const initFilter: IFilter = {
  currentPage: 1,
  pageSize: PAGE_SIZE,
  sortBy: 'created_at',
  sortDirection: 'desc',
}

const instance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  withXSRFToken: true,
})

const authInstance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  withXSRFToken: true,
})

authInstance.interceptors.response.use(
  response => response,
  ({ response: { status, data } }) => {
    if (status === 401 || status === 419) {
      useStore.setState(({ auth }) => ({
        auth: { ...auth, currentUser: null },
      }))
      //history.push('/login')
    }
    return Promise.reject<ResponseError>({
      status,
      message:
        data?.message ||
        data?.error ||
        'Something went wrong, unable to complete request',
      errors: data?.errors || undefined,
      errorCode: data?.error_code || undefined,
    })
  },
)

if (process.env.NODE_ENV === 'development') {
  instance.defaults.headers.common['Authorization'] = 'Bearer ' + AUTH_TOKEN
  authInstance.defaults.headers.common['Authorization'] = 'Bearer ' + AUTH_TOKEN
}

export default authInstance

export { instance }
